input[type="text"]::placeholder,
textarea::placeholder {
  color: gray;
  width: 50%;
}

input[type="text"],
textarea {
  border-radius: 2px;
  border: 1px solid #a2a2a2;
}

.marginCoteContact {
  text-align: left;
  margin: 80px 40px;
  padding: 50px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
}

.litlePaddingCOntact {
  padding: 5px 0;
}

.boxShadow {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
}

.err {
  color: red;
  /* height: 50px; */
  padding: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.contactGauche {
  width: 80%;
}

.Toastify__toast-container--top-right {
  top: 3em !important;
  right: 3em !important;
}

@media all and (min-device-width: 200px) and (max-device-width: 780px) {
  .contactGauche {
    width: 100%;
  }

  .contactDroite {
    display: none;
  }

  .marginBottomPlus {
    margin-bottom: 50px;
  }
  input[type="text"]::placeholder,
  textarea::placeholder {
    color: gray;
  }

  input[type="text"],
  textarea {
    border-radius: 2px;
    border: 1px solid #a2a2a2;
  }

  .err {
    color: red;
    /* height: 50px; */
  }

  .Toastify__toast-container {
    font-size: 35px;
    border-radius: 5px;
    width: auto !important;
    max-width: 60% !important;
    min-width: 40% !important;
  }

  .Toastify__toast-container--top-right {
    top: 2em !important;
    right: 2em !important;
  }

  .Toastify__toast {
    padding: 25px 20px !important;
    font-family: "Fontype Rounded" !important;
  }

  .Toastify__toast-icon {
    width: 40px !important;
    padding: 0 20px 0 0;
  }

  .Toastify__toast-body {
    padding-left: 0px !important;
  }

  .Toastify__close-button svg {
    width: 30px !important;
    height: 30px !important;
    fill: black !important;
  }
}
