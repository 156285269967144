.grid4_2_1,
.grid4_2_2 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 40px;
  padding: 40px;
}

.grid3_2_1,
.grid3_2_2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  padding: 40px;
}

.grid2_2_1,
.grid2_2_2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  padding: 40px;
}

@media all and (min-device-width: 781px) and (max-device-width: 1200px) {
  .grid4_2_1,
  .grid4_2_2,
  .grid3_2_1,
  .grid3_2_2,
  .grid2_2_1,
  .grid2_2_2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media all and (min-device-width: 200px) and (max-device-width: 780px) {
  .grid4_2_1,
  .grid3_2_1,
  .grid2_2_1 {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 70px;
  }

  .grid4_2_2,
  .grid3_2_2,
  .grid2_2_2 {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 70px 35px;
  }
}
