.bc-footer {
  background-color: rgb(68, 68, 68);
  margin: 0 auto;
}

.footer1 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 0;
}

.footer2 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
}
.hrFooter {
  background-color: rgba(81, 86, 129, 0.72);
  height: 2px;
  border: none;
}

.SvgReseaux {
  width: 25px;
  height: 25px;
}

.grid4Footer {
  display: grid;
  grid-gap: 150px;
  grid-template-columns: repeat(3, 1fr);
}

.bouleNoire {
  height: 8px;
  width: 8px;
  display: inline-block;
  background-color: white;
  border-radius: 50%;
  margin: 0 10px 0 25px;
}

@media all and (min-device-width: 780px) and (max-device-width: 1400px) {
  .footer1 {
    max-width: 80%;
    margin: 0 auto;
  }

  .footer2 {
    max-width: 80%;
    margin: 0 auto;
  }

  .gap20Footer {
    gap: 20px;
  }

  .grid4Footer {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 100px 0px;
  }
}

@media all and (min-device-width: 200px) and (max-device-width: 780px) {
  .grid4Footer {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 180px 40px;
  }

  .footer1 {
    max-width: 90%;
    margin: 0 auto;
    padding: 140px 0;
  }

  .footer2 {
    max-width: 90%;
    margin: 0 auto;
    padding: 40px 0;
  }

  .hrFooter {
    height: 2px;
  }

  .SvgReseaux {
    width: 55px;
    height: 55px;
  }

  .gap20Footer {
    gap: 40px;
  }

  .bouleNoire {
    height: 20px;
    width: 20px;
    margin: 0 15px 0 60px;
  }
}
