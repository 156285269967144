@media all and (min-device-width: 200px) and (max-device-width: 780px) {
  .barMenu {
    font-size: 60px;
    font-weight: 900;
  }

  .imageLogo {
    width: 120px;
  }

  .absoluteMenu {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    height: 100vh;
    overflow: scroll;
    z-index: 999;
    border-right: 1px solid white;
  }

  .MenuLeft {
    left: -79%;
    width: 78%;
  }

  .MenuRight {
    right: -79%;
    width: 78%;
  }

  .divCloseMenu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99;
  }

  .openMenuLeft {
    animation: openMenuLeft 0.2s ease-in forwards;
  }

  @keyframes openMenuLeft {
    from {
      left: -79%;
    }

    to {
      left: 0;
    }
  }

  .closeMenuLeft {
    animation: closeMenuLeft 0.2s ease-in forwards;
  }

  @keyframes closeMenuLeft {
    from {
      left: 0;
    }

    to {
      left: -79%;
    }
  }

  .openMenuRight {
    animation: openMenuRight 0.2s ease-in forwards;
  }

  @keyframes openMenuRight {
    from {
      right: -79%;
    }

    to {
      right: 0;
    }
  }

  .closeMenuRight {
    animation: closeMenuRight 0.2s ease-in forwards;
  }

  @keyframes closeMenuRight {
    from {
      right: 0;
    }

    to {
      right: -79%;
    }
  }

  .croixCloseMenu {
    font-size: 80px;
    right: 20px;
    top: 15px;
    z-index: 999;
  }

  .separtor {
    padding: 2px 0;
    margin: 40px 0;
  }
}

.fixedMenu {
  position: fixed;
  width: 100vw;
  box-sizing: border-box;
  top: 0;
}
