.logo {
  max-width: 100px;
  max-height: 100px;
}

.hoverSousMenu {
  display: none !important;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  min-width: 100%;
}

.overLiMenu:hover .hoverSousMenu {
  display: block !important;
}

.overLiMenu:hover {
  background-color: orange;
  border-radius: 5px 5px 0 0;
}

.hoverSousMenu a:last-child .hover {
  border-radius: 0 0 5px 5px;
}
