.bc_gradient {
  background: linear-gradient(113.49deg, #984d38 -30.3%, #181e41 58.12%);
}

.bc_gradientOrange {
  height: 84px;
  width: 84px;
  background: linear-gradient(237.74deg, #ff9a02 11.89%, #ff2e00 88.32%);
}

.bc_etoile_formation {
  height: 38px;
  width: 38px;
  background: linear-gradient(113.49deg, #984d38 -30.3%, #181e41 58.12%);
}

.bc_etoile_stage {
  height: 50px;
  width: 50px;
  background: linear-gradient(113.49deg, #984d38 -30.3%, #181e41 58.12%);
}

.bc-gradientOrange {
  height: 84px;
  width: 84px;
  background: linear-gradient(237.74deg, #ff9a02 11.89%, #ff2e00 88.32%);
}

.elipse {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ff9a02;
  margin: 2px;
  border: 1px solid #ff9a02;
}

.textGradientOrange {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(237.74deg, #ff9a02 11.89%, #ff2e00 88.32%);
  -webkit-background-clip: text;
}

.soulignerTexte {
  background: linear-gradient(237.74deg, #ff9a02 11.89%, #ff2e00 88.32%);
}

.iconHobbies {
  font-size: 50px;
}

@media all and (min-device-width: 200px) and (max-device-width: 780px) {
  .grid4_2_2 {
    grid-gap: 35px !important;
  }

  .elipse {
    width: 22px;
    height: 22px;
  }

  .bc-gradientOrange {
    height: 180px;
    width: 180px;
  }
  .iconHobbies {
    font-size: 80px;
  }

  .fa-star {
    font-size: 40px;
  }

  .bc_etoile_stage,
  .bc_etoile_formation {
    width: 90px;
    height: 90px;
  }
}
