.soulignerTitre {
  width: 10%;
  margin: 20px auto 30px auto;
  height: 4px;
  background-color: black;
}

@media all and (min-device-width: 200px) and (max-device-width: 780px) {
  .soulignerTitre {
    width: 24%;
    margin: 20px auto 30px auto;
    height: 7px;
    background-color: black;
  }
}
