.logo {
  max-width: 100px;
  max-height: 100px;
}

.hoverSousMenu {
  display: none !important;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  min-width: 100%;
}

.overLiMenu:hover .hoverSousMenu {
  display: block !important;
}

.overLiMenu:hover {
  background-color: blue;
  border-radius: 5px 5px 0 0;
}

.hoverSousMenu a:last-child .bcMenuOver {
  border-radius: 0 0 5px 5px;
}

.bcMenuOver {
  background-color: rgb(19, 57, 138);
}

.bcMenuOver:hover {
  filter: brightness(0.9);
  cursor: pointer;
}

.bcMenuOver:hover {
  filter: brightness(0.5);
  cursor: pointer;
}
