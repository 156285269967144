.btn {
  letter-spacing: 1px;
  outline: none;
  padding: 17px 35px;
  transition: all 0.3s;
  border-radius: 6px;
  display: inline-block;
  line-height: normal;
  font-family: Open Sans, sans-serif;
  background-color: #4175fc;
  font-weight: 700;
  color: white;
}

.btn:hover {
  cursor: pointer;
  background-color: white;
  color: #4175fc;
}

.btnContact:hover {
  cursor: pointer;
  background-color: #0609d1;
}

.hover-white:hover {
  color: white;
}

.hover-green:hover {
  color: green;
}

.hover-gray:hover {
  color: gray;
}

.hover-bleu:hover {
  color: #4175fc;
}

.hoverBc-bleuFoncer:hover {
  background-color: #084af3;
}

.hover-black:hover {
  color: black;
}

.hoverBc-white:hover {
  background-color: white;
}

.hoverBc-gray:hover {
  background-color: gray;
}

.hoverBc-bleu:hover {
  background-color: #4175fc;
}

.hoverBc-black:hover {
  background-color: black;
}

@media all and (min-device-width: 200px) and (max-device-width: 780px) {
  .btn {
    padding: 25px 80px;
  }
}
