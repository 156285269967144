.imageBackground {
  background-position: center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  width: 100%;
  height: 280px;
}

.width400 {
  height: 280px;
}

.afficherTexteOver:hover {
  cursor: pointer;
  border: 5px solid orangered;
  opacity: 1;
  animation: opacity1 500ms ease-in forwards;
}

@keyframes opacity1 {
  to {
    opacity: 1;
  }

  from {
    opacity: 0;
  }
}
