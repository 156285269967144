.load {
  width: 100vw;
  height: 100vh;
  background-color: gray;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999999999;
}

.imgLoader {
  width: 60px;
}
/* 
.false {
  display: none;
} */

@media all and (min-device-width: 200px) and (max-device-width: 780px) {
  .imgLoader {
    width: 110px;
  }
}
