.logo {
  max-width: 100px;
  max-height: 100px;
}

.hoverSousMenu {
  display: none !important;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  min-width: 100%;
}

.overLiMenu:hover .hoverSousMenu {
  display: block !important;
}

.overLiMenu:hover {
  background-color: rgb(0, 0, 0);
  border-radius: 5px 5px 0 0;
}

.hoverSousMenu a:last-child .bcMenuOver {
  border-radius: 0 0 5px 5px;
}

.bcMenuOver {
  background-color: gray;
}

.bcMenuOver:hover {
  filter: brightness(0.9);
  cursor: pointer;
}

.styleImageBackgroundNoFull {
  height: 50vh;
  min-height: 500px;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.filtreImage {
  height: 100%;
  width: 100%;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #101218;
}

.absoluteCenterTop60 {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}
